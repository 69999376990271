import { useEffect } from 'react'
import { useDeleteTokenCookie } from '../useDeleteTokenCookie/useDeleteTokenCookie'

export const useDeleteTokenCookieOnMount = () => {
  const deleteTokenCookie = useDeleteTokenCookie()

  useEffect(() => {
    deleteTokenCookie()
  }, [deleteTokenCookie])
}
