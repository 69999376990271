import { size, find, first } from 'lodash'
import { ApolloError, useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// eslint-disable-next-line import/no-named-as-default
import Router, { useRouter } from 'next/router'
import { Box, Container, Stack, Typography } from '@mui/material'
import Head from 'next/head'
import { Button } from 'settle-ui/components/Button/Button'
import { GoogleAuth, TGoogleCredential } from 'settle-ui/components/GoogleAuth/GoogleAuth'
import { DividersBlock } from 'settle-ui/components/DividersBlock/DividersBlock'
import { TextFieldController } from 'settle-ui/form/TextFieldController/TextFieldController'
import { PasswordInputController } from 'settle-ui/form/PasswordInputController/PasswordInputController'
import { useSnackbars } from 'settle-ui/hooks/useSnackbars/useSnackbars'
import { ExternalAppLayout } from 'pages/components/ExternalAppLayout/ExternalAppLayout'
import { externalLinks } from 'lib/consts/externalLinks'
import { logEvent } from 'lib/tracking/segment'
import { localStorageKeys } from 'lib/consts/browserStorage'
import { ERouterPage } from 'lib/navigation/consts'
import { getLSItem } from 'lib/helpers/getLSItem/getLSItem'
import { isNextUrlValid } from 'lib/helpers/isNextUrlValid/isNextUrlValid'
import { useSetTokenCookie } from 'lib/hooks/useSetTokenCookie/useSetTokenCookie'
import { EMAIL_PATTERN } from 'lib/patterns'
import { useBrandName } from 'lib/hooks/useBrandName/useBrandName'
import { qsStringSchema } from 'lib/zod'
import { useDeleteTokenCookieOnMount } from 'lib/hooks/useDeleteTokenCookieOnMount/useDeleteTokenCookieOnMount'
import { getClientManagerClientsPath } from 'lib/navigation/helpers/getClientManagerClientsPath/getClientManagerClientsPath'
import { getBusinessRootPath } from 'lib/navigation/helpers/getBusinessRootPath/getBusinessRootPath'
import { MetaTags } from '../components/MetaTags/MetaTags'
import { useUrlBasedSnackbar } from '../hooks/useUrlBasedSnackbar/useUrlBasedSnackbar'
import { useExistingVendorWarning } from './hooks/useExistingVendorWarning/useExistingVendorWarning'
import * as events from './LoginPage.events'
import {
  LoginPage_ProfileFragment,
  LoginPage_signInDocument,
  LoginPage_authProviderSignInDocument,
} from './LoginPage.graphql'
import { CreateAccountLink } from './components/CreateAccountLink/CreateAccountLink'
import { ForgotPasswordLink } from './components/ForgotPasswordLink/ForgotPasswordLink'
import { isClientManagerRole } from 'pages/helpers/isClientManagerRole/isClientManagerRole'

export interface ILoginForm {
  email: string
  password: string
}

export const LoginPage = () => {
  useExistingVendorWarning()
  useUrlBasedSnackbar({ permanent: true })

  const setTokenCookie = useSetTokenCookie()
  const brand = useBrandName()
  const router = useRouter()

  const next = qsStringSchema.parse(router.query.next)
  const email = qsStringSchema.parse(router.query.email)

  const { t } = useTranslation()
  const { showApolloOrTextError, showError } = useSnackbars()

  const [signIn] = useMutation(LoginPage_signInDocument)
  const [signInGoogle] = useMutation(LoginPage_authProviderSignInDocument)
  const [isLoading, setIsLoading] = useState(false)

  const form = useForm<ILoginForm>({ defaultValues: { email, password: '' } })
  const formEmail = form.watch('email')
  const { reset } = form

  useDeleteTokenCookieOnMount()

  useEffect(() => {
    reset({ email })
  }, [email, reset])

  const onSuccess = (profile: Maybe<LoginPage_ProfileFragment>) => {
    const next = new URLSearchParams(document.location.search).get('next')

    if (next && isNextUrlValid(next)) {
      return document.location.assign(next)
    }

    const { actions, memberships = [], accountingFirms = [] } = profile || {}

    if (size(memberships) === 0) {
      setIsLoading(false)
      showError(t('noBusinessLoginErrorMessage'))
      return null
    }

    const businessId = getLSItem(localStorageKeys.businessId)

    if (actions?.viewAccountantDashboard?.allowed) {
      const clientManagerBusiness = find(accountingFirms, ({ id }) => id === businessId) || first(accountingFirms)

      if (clientManagerBusiness) {
        const { role } = find(memberships, ({ business }) => business?.id === clientManagerBusiness.id) ?? {}
        const hasAccessibleParentBusinessRole = role && !isClientManagerRole(role)

        return Router.push({
          pathname: getClientManagerClientsPath(clientManagerBusiness.id),
          ...(hasAccessibleParentBusinessRole && {
            query: {
              back_path: getBusinessRootPath(clientManagerBusiness.id),
              back_business_name: clientManagerBusiness.officialName,
            },
          }),
        })
      }
    }

    if (businessId && memberships.find(({ business }) => business?.id === businessId)) {
      return Router.push(getBusinessRootPath(businessId))
    }

    return Router.push(ERouterPage.root)
  }

  const handleGoogleSuccess = async (jwt: string, credential: TGoogleCredential) => {
    try {
      setIsLoading(true)

      const { data } = await signInGoogle({ variables: { data: jwt } })
      const { token, profile } = data?.authProviderSignIn ?? {}

      if (token) {
        setTokenCookie(token)
        onSuccess(profile)
        setTimeout(() => google.accounts.id.revoke(credential.email), 1000)
      }
    } catch (err) {
      google.accounts.id.revoke(credential.email)
      setIsLoading(false)
      showApolloOrTextError(err, t('failedToSignIn'))
    }
  }

  const handleSubmit = async ({ email, password }: ILoginForm) => {
    logEvent(events.LOGIN_BUTTON_CLICK)

    try {
      setIsLoading(true)

      const { data } = await signIn({ variables: { email, password } })
      const { token, mfaToken, totpSetupInfo, profile } = data?.signIn ?? {}

      if (token) {
        setTokenCookie(token)
        onSuccess(profile)
        return
      }

      if (mfaToken && totpSetupInfo) {
        Router.push({ pathname: ERouterPage.loginSetup, query: { email, password } }, ERouterPage.loginSetup)
        return
      }

      if (mfaToken) {
        window.localStorage.setItem(localStorageKeys.mfaToken, mfaToken)
        window.localStorage.setItem(localStorageKeys.mfaEmail, email)
        Router.push({ pathname: ERouterPage.loginVerify, query: next && { next } })
      }
    } catch (err) {
      setIsLoading(false)

      if (err instanceof ApolloError) {
        const [error] = err.graphQLErrors

        if (error?.extensions?.code === 'account_locked') {
          showApolloOrTextError(err, t('failedToSignIn'), {
            text: t('contactSupport'),
            onClick: () => (window.location.href = `mailto:${externalLinks.supportEmail}`),
          })
          return
        }
      }

      showApolloOrTextError(err, t('failedToSignIn'), { id: 'login-page_apollo_error' })
    }
  }

  return (
    <>
      <Head>
        <MetaTags title={brand} description={t('loginPage.meta.description')} />
      </Head>
      <ExternalAppLayout>
        <Container
          maxWidth="xs"
          sx={(theme) => ({
            marginTop: theme.spacing(3),
            [theme.breakpoints.down('md')]: {
              paddingLeft: theme.spacing(3),
              paddingRight: theme.spacing(3),
            },
          })}
        >
          <Typography align="center" variant="h3" color="primary" mt={3} mb={4}>
            {t('login')}
          </Typography>
          <GoogleAuth onSuccess={handleGoogleSuccess} />
          <DividersBlock sx={{ margin: ({ spacing }) => spacing(2, 0, 0) }}>
            <Typography variant="body2" color="textSecondary">
              Or
            </Typography>
          </DividersBlock>
          <form noValidate={true} onSubmit={form.handleSubmit(handleSubmit)} method="post">
            <TextFieldController
              size="large"
              color="secondary"
              type="email"
              name="email"
              data-test-id="email-field"
              control={form.control}
              label="Email"
              rules={{
                pattern: { value: EMAIL_PATTERN, message: t('emailIsIncorrect') },
                required: { value: true, message: t('emailCantBeEmpty') },
              }}
            />
            <PasswordInputController
              size="large"
              margin="normal"
              color="secondary"
              name="password"
              data-test-id="password-field"
              control={form.control}
              label="Password"
              rules={{
                required: { value: true, message: t('passwordCantBeEmpty') },
              }}
            />
            <Box sx={{ textAlign: 'center' }}>
              <Button
                type="submit"
                variant="primary"
                loading={isLoading}
                size="large"
                sx={(theme) => ({
                  width: '230px',
                  marginTop: theme.spacing(3),
                  marginBottom: theme.spacing(2),
                  [theme.breakpoints.down('md')]: {
                    width: '100%',
                  },
                })}
              >
                {t('logIn')}
              </Button>
            </Box>
          </form>
          <Stack gap={1} alignItems="center">
            <ForgotPasswordLink email={formEmail} />
            <CreateAccountLink />
          </Stack>
        </Container>
      </ExternalAppLayout>
    </>
  )
}
