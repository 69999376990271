export const isNextUrlValid = (next: string) => {
  const urlPattern = new RegExp(
    '^(\\/[-a-z\\d%_.~+]*)*' + // validate path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ) // validate fragment locator

  return urlPattern.test(next)
}
