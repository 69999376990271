interface IParams {
  search?: string
}

/**
 * Returns the client manager path with optional search query.
 */
export const getClientManagerClientsPath = (businessId: Maybe<string>, { search }: IParams = {}) => {
  let path = `/${businessId}/client-manager/clients`

  if (search) {
    path += `?${search}`
  }

  return path
}
