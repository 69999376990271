import { ERouterPage } from 'lib/navigation/consts'

interface IParams {
  search?: string
}

/**
 * When businessId is provided, returns the root path to the business.
 * Otherwise returns the root path of the app
 */
export const getBusinessRootPath = (businessId: Maybe<string>, params: IParams = {}) => {
  const path = businessId ? `/${businessId}` : ERouterPage.root

  return path + (params.search ? `?${params.search}` : '')
}
