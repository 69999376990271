import isNil from 'lodash/isNil'

type TRefs<T = unknown> = Maybe<React.MutableRefObject<T> | React.LegacyRef<T> | React.RefObject<T>>
/**
 * Merges array of refs (e.g. local and external forwarded refs)
 * @param {React.Ref[]} refs - Array of refs (objects or functions)
 * @returns {void}
 */
export const mergeRefs =
  <T = unknown>(...refs: (TRefs<T> | TRefs<T>[])[]): React.RefCallback<T> =>
  (value) => {
    refs.flat().forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value)
      } else if (!isNil(ref)) {
        ;(ref as React.MutableRefObject<T | null>).current = value
      }
    })
  }
